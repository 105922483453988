import { Icon, LoadingIcon, Tooltip } from '@changex/design-system'
import { PaymentAccount } from 'features/funds/types'
import classNames from 'classnames'
import { useQueries } from '@tanstack/react-query'
import { httpClient } from '@api'

const fetchStripeAccountStatus = async (connectAccountId: string) => {
  const { data: response } = await httpClient.get(
    `/payments/stripe_account_statuses?filter[connect_account_id]=${connectAccountId}`
  )

  return response.data
}

const tooltipContent = (paymentAccount: PaymentAccount) => {
  return (
    <>
      {paymentAccount.paymentPlatformAccountName}:{` `}
      {paymentAccount.status.enabled
        ? 'Enabled'
        : paymentAccount.status.enabled === false
        ? 'Disabled (see the Payments tab in the application details for more details)'
        : 'Checking...'}
    </>
  )
}

export function ApplicationPaymentAccountsStatus({
  paymentAccounts = [],
  onlyFailedPaymentsForAllocation = false,
}: {
  paymentAccounts?: PaymentAccount[]
  onlyFailedPaymentsForAllocation?: boolean
}) {
  const stripePaymentAccountStatusQueries = useQueries({
    queries: paymentAccounts
      .filter((pa) => {
        return pa.accountType === 'stripe' && pa.status.enabled === null
      })
      .map((paymentAccount) => {
        return {
          queryKey: ['connectAccountId', paymentAccount.accountIdentifier],
          queryFn: async () =>
            await fetchStripeAccountStatus(paymentAccount.accountIdentifier),
        }
      }),
  })

  stripePaymentAccountStatusQueries.forEach((paq) => {
    if (!paq.isFetching && paq.isSuccess) {
      const paymentAccount = paymentAccounts.find((pa) => {
        return pa.accountIdentifier === paq.data.attributes.connectAccountId
      })

      if (!paymentAccount) return false

      paymentAccount.status.enabled = paq.data.attributes.enabled
      paymentAccount.status.description = paq.data.attributes.description
    }
  })

  return (
    <Tooltip>
      <Tooltip.Trigger>
        <div className="flex items-center gap-x-1">
          {stripePaymentAccountStatusQueries.some(
            (query) => query.isFetching
          ) ? (
            <LoadingIcon />
          ) : (
            <Icon
              icon="CheckCircle"
              size="sm"
              variant="solid"
              className={classNames('', {
                'text-gray-300': paymentAccounts.length === 0,
                'text-green-500':
                  paymentAccounts.some((account) => account.status.enabled) &&
                  !onlyFailedPaymentsForAllocation,
                'text-red-500':
                  (paymentAccounts.length !== 0 &&
                    !paymentAccounts.some(
                      (account) => account.status.enabled
                    )) ||
                  onlyFailedPaymentsForAllocation,
              })}
            />
          )}
          <div className="text-sm text-gray-500">Payment account</div>
        </div>
      </Tooltip.Trigger>
      <Tooltip.Content>
        {onlyFailedPaymentsForAllocation && (
          <div className="mb-2">
            Previous payments made to this project have been returned, see the
            payments tab for more details.
          </div>
        )}
        <>
          {paymentAccounts.length === 0
            ? 'This starter does not have any payment accounts.'
            : paymentAccounts.map((paymentAccount) => (
                <div key={paymentAccount.id}>
                  {tooltipContent(paymentAccount)}
                </div>
              ))}
        </>
      </Tooltip.Content>
    </Tooltip>
  )
}
