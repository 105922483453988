import NumberFormat from 'react-number-format'
import { useFormContext, useController, useFieldArray } from 'react-hook-form'
import toast from 'react-hot-toast'
import { TrashIcon } from '@heroicons/react/outline'
import SolutionSelector from './solution-selector'
import { LowerCaseCurrencyIso } from '@changex/design-system'
import {
  SolutionDefaultAllocations,
  TAllocationForCurrency,
} from 'shared/types/fund-editor.type'
import classNames from 'classnames'

type HeaderCellProps = {
  className: string
  children: React.ReactNode
}

type EditableCellProps = {
  name: string
  isAllowed: (value: number | undefined) => boolean
}

type IdeaRowProps = {
  field: any // TODO:
  index: number
  onDelete: () => void
}

const currencyAllocation = (
  value: Partial<TAllocationForCurrency> | undefined,
  currency: LowerCaseCurrencyIso
): number => {
  // NOTE: CurrencyIso values as JSON keys will be forced to lowercase
  //       by the API, so we must use lowercase when dealing with these
  if (value) return value[currency] || 0

  return 0
}

const HeaderCell = ({ className, children }: HeaderCellProps) => {
  return (
    <div
      className={`px-6 py-3 text-xs font-medium uppercase tracking-wider text-gray-500 ${className}`}
    >
      {children}
    </div>
  )
}

const ReadOnlyCell = ({ value }: { value: string }) => {
  return (
    <div className="flex items-center whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500">
      <NumberFormat
        thousandSeparator={true}
        displayType={'text'}
        decimalScale={0}
        value={value}
        isNumericString
      />
    </div>
  )
}

const EditableCell = ({ name, isAllowed }: EditableCellProps) => {
  const { control } = useFormContext()
  const {
    field: { onChange, value },
  } = useController({
    name,
    control,
  })

  return (
    <div className="flex items-center">
      <NumberFormat
        thousandSeparator={true}
        decimalScale={0}
        allowNegative={false}
        defaultValue={value}
        onValueChange={(values) => {
          onChange(values.floatValue)
        }}
        placeholder=""
        className={classNames(
          'block w-full rounded-md border-transparent bg-transparent px-6 py-4 text-right sm:text-sm',
          { 'border-red-500': !isAllowed(value) }
        )}
        isAllowed={({ floatValue }) => isAllowed(floatValue)}
        data-testid={name}
      />
    </div>
  )
}

const isGreaterThanZero = (floatValue: number | undefined): boolean => {
  return floatValue ? floatValue >= 1 : false
}

const isPresent = (floatValue: number | undefined): boolean => {
  return floatValue !== undefined ? true : false
}

const IdeaRow = ({ field, index, onDelete }: IdeaRowProps) => {
  const { watch } = useFormContext()
  const watchedSeed = watch(`options.portfolio[${index}].allocations.seed`)
  const watchedImpact = watch(`options.portfolio[${index}].allocations.impact`)
  const watchedInnovator = watch(
    `options.portfolio[${index}].solutionAllocations.seed`
  )

  watch(`options.portfolio[${index}].maxReplications`)

  return (
    <div className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
      <hr />
      <div className="grid grid-cols-8">
        <div className="col-span-2 px-2 py-4 text-sm font-medium text-gray-900">
          {field.solutionId}
        </div>
        <EditableCell
          name={`options.portfolio[${index}].maxReplications`}
          isAllowed={isPresent}
        />
        <EditableCell
          name={`options.portfolio[${index}].allocations.seed`}
          isAllowed={isGreaterThanZero}
        />
        <EditableCell
          name={`options.portfolio[${index}].allocations.impact`}
          isAllowed={isGreaterThanZero}
        />
        <EditableCell
          name={`options.portfolio[${index}].solutionAllocations.seed`}
          isAllowed={isPresent}
        />
        <ReadOnlyCell value={watchedSeed + watchedImpact + watchedInnovator} />
        <div className="flex justify-center">
          {/* TODO: disable for those already in use */}
          <button type="button" onClick={onDelete}>
            <TrashIcon className="h-5 w-5 text-gray-500" />
          </button>
        </div>
      </div>
    </div>
  )
}

const TotalRow = ({ rows }, { rows: Array }) => {
  const combinedTotal = rows.reduce((result, row) => {
    if (row.maxReplications === 0 || row.maxReplications === undefined) {
      return result
    }

    const individualReplicationCost =
      row.allocations.seed +
      row.allocations.impact +
      row.solutionAllocations.seed
    return (result += individualReplicationCost * row.maxReplications)
  }, 0)

  return (
    <>
      <hr />
      <div className="grid grid-cols-8">
        <div className="col-span-6 flex items-center justify-end py-4 text-right text-xs font-bold uppercase text-gray-500">
          Projected total
        </div>
        <div className="flex items-center whitespace-nowrap px-6 py-4 text-right text-sm font-medium text-gray-900">
          <NumberFormat
            thousandSeparator={true}
            displayType={'text'}
            decimalScale={0}
            value={combinedTotal}
            isNumericString
          />
        </div>
      </div>
    </>
  )
}

const PortfolioEditor = () => {
  const { getValues, watch } = useFormContext()
  const { fields, append, remove } = useFieldArray({
    name: 'options.portfolio',
    // @ts-ignore
    defaultValue: [],
  })

  const currency = getValues(
    'options.currency'
  ).toLowerCase() as LowerCaseCurrencyIso
  const watchedRows = watch('options.portfolio')

  const addIdea = (
    solutionId: string,
    defaultAllocations?: SolutionDefaultAllocations
  ) => {
    if (solutionId) {
      append({
        solutionId,
        maxReplications: 0,
        allocations: {
          seed: currencyAllocation(
            defaultAllocations?.allocations.seed,
            currency
          ),
          impact: currencyAllocation(
            defaultAllocations?.allocations.impact,
            currency
          ),
        },
        solutionAllocations: {
          seed: currencyAllocation(
            defaultAllocations?.solutionAllocations.seed,
            currency
          ),
        },
      })
    } else {
      toast.error('Oops! You need to fill in a solution id')
    }
  }

  return (
    <div data-testid="portfolio-editor" className="flex flex-col gap-4">
      {fields.length > 0 && (
        <div>
          <div className="grid grid-cols-8">
            <HeaderCell className="col-span-2 text-left">Name</HeaderCell>
            <HeaderCell className="text-right">Limit</HeaderCell>
            <HeaderCell className="text-right">Seed</HeaderCell>
            <HeaderCell className="text-right">Impact</HeaderCell>
            <HeaderCell className="text-right">Innovator</HeaderCell>
            <HeaderCell className="text-right">Cost</HeaderCell>
          </div>
          <div>
            {fields.map((field, index) => (
              <div key={field.id}>
                <IdeaRow
                  field={field}
                  index={index}
                  onDelete={() => remove(index)}
                />
              </div>
            ))}
          </div>
          <TotalRow rows={watchedRows} />
        </div>
      )}
      <div className="text-center text-gray-500">
        {`${fields.length} idea${fields.length === 1 ? '' : 's'}`}
      </div>
      <div className="flex justify-center">
        <SolutionSelector
          onChange={(
            solutionId: string,
            defaultAllocations?: SolutionDefaultAllocations
          ) => {
            addIdea(solutionId, defaultAllocations)
          }}
        />
      </div>
    </div>
  )
}

export default PortfolioEditor
