import { Button, WithTooltip } from '@changex/design-system'
import { InlineTextLoadingIndicator } from '@components'
import { useState } from 'react'
import { ConfirmationModal } from 'components/confirmation-modal'
import { UserFundRole } from '@features/funds/types'
import { useFund } from 'shared/providers'

type Props = {
  onClick: () => void
  isLoading: boolean
  rejectActionText?: string
  tooltip?: string
}

const CLASS_NAMES =
  'py-1 font-normal border-red-600 text-orange-500 hover:border-red-800 hover:text-orange-700'

const CONFIRMATION_TEXT = {
  Reject:
    'Are you sure you want to reject this location? This action will release seed and impact allocations.',
  'Reject and refund':
    'Are you sure you want to reject and refund this location? This action will release SEED and IMPACT allocation, including paid.',
  Fail: 'Are you sure you want to fail this location? This action will release the impact allocation.',
}

export function ApplicationApprovalRejectButton({
  onClick,
  isLoading,
  rejectActionText = 'Reject',
  tooltip,
}: Props) {
  const fund = useFund()
  const [open, setOpen] = useState(false)
  const clickHandler = () => {
    setOpen(!open)
  }
  const okText = `Yes, ${rejectActionText} this location`

  if (fund.userRole !== UserFundRole.Admin) {
    return null
  }

  return (
    <>
      {tooltip ? (
        <WithTooltip tooltip={tooltip}>
          <Button
            weight="tertiary"
            onClick={clickHandler}
            className={CLASS_NAMES}
            disabled={isLoading}
          >
            <InlineTextLoadingIndicator isLoading={isLoading}>
              {rejectActionText}
            </InlineTextLoadingIndicator>
          </Button>
        </WithTooltip>
      ) : (
        <Button
          weight="tertiary"
          onClick={clickHandler}
          className={CLASS_NAMES}
          disabled={isLoading}
        >
          <InlineTextLoadingIndicator isLoading={isLoading}>
            {rejectActionText}
          </InlineTextLoadingIndicator>
        </Button>
      )}
      <ConfirmationModal
        open={open}
        onClick={onClick}
        setOpen={setOpen}
        text={CONFIRMATION_TEXT[rejectActionText]}
        okText={okText}
      />
    </>
  )
}
