import { Skeleton as SkeletonDS } from '@changex/design-system'

const Skeleton = () => (
  <div
    className="flex justify-between rounded border border-t-0 border-gray-200 p-6 first:border-t"
    data-testid="skeleton"
  >
    <div className="flex gap-x-4">
      <SkeletonDS className="h-11 w-11 rounded-full" />
      <div className="flex flex-col gap-y-6">
        <div className="flex flex-col gap-y-2">
          <SkeletonDS className="h-5 w-[150px]" />
          <SkeletonDS className="h-5 w-[200px]" />
        </div>
        <div className="flex flex-col gap-y-2">
          <SkeletonDS className="h-5 w-[220px]" />
          <SkeletonDS className="h-5 w-[175px]" />
        </div>
      </div>
    </div>
    <div className="flex flex-col gap-y-3">
      <div className="flex gap-x-2">
        <SkeletonDS className="h-5 w-[50px]" />
        <SkeletonDS className="h-5 w-[100px]" />
      </div>
      <div className="flex gap-x-2">
        <SkeletonDS className="h-5 w-[90px]" />
        <SkeletonDS className="h-5 w-[150px]" />
      </div>
      <div className="flex gap-x-2">
        <SkeletonDS className="h-5 w-[70px]" />
        <SkeletonDS className="h-5 w-[120px]" />
      </div>
    </div>
    <div className="flex flex-col gap-y-2">
      <div className="flex gap-x-6">
        <SkeletonDS className="h-5 w-[50px]" />
        <SkeletonDS className="h-5 w-[150px]" />
      </div>
      <div className="flex flex-col gap-y-4">
        <SkeletonDS className="h-5" />
        <SkeletonDS className="h-5 w-[85px]" />
      </div>
    </div>
  </div>
)

export default Skeleton
