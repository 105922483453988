import React from 'react'
import { FundApplicationOpenGrant } from '../../../types'
import ApplicationDetailsCall from '../application-details-call'
import ApplicationDetailsInfo from '../application-details-info'
import ApplicationDetailsNotes from '../application-details-notes'
import ApplicationDetailsSteps from '../application-details-steps'

import Divider from '../../../../../components/divider'
import DetailsTable from '../../../../../components/details-table'
import { useCurrencyFormatter } from '../../../../../shared/currency/hooks/use-currency-formatter'
import { ApplicationDetailsHeardAboutUs } from '@features/funds/components/application-details/application-details-heard-about-us'
import { AdminOnly } from '../../../../../shared/components/admin-only'
import { When } from 'react-if'

type Props = {
  data: FundApplicationOpenGrant
}

function getAdditionalDataRows(
  data: Object,
  category: string
): Array<{ label: string; value: string }> {
  if (data === undefined || data === null || !(category in data)) return []

  return data[category].map((item) => ({
    label: item['question'],
    value: item['answer'],
  }))
}

export function ApplicationDetailsOverviewOpenGrant({ data }: Props) {
  const applicantRows = getAdditionalDataRows(data.additionalData, 'applicant')
  const formatCurrency = useCurrencyFormatter()

  return (
    <>
      <ApplicationDetailsInfo data={data} />
      <Divider className="pb-10" />
      <ApplicationDetailsSteps data={data} />
      <Divider className="pb-8" />
      <ApplicationDetailsNotes data={data} />
      <div className="mt-12 mb-8">
        <ApplicationDetailsCall data={data} />
      </div>
      <AdminOnly>
        <Divider className="mb-8 pb-8" />
        <ApplicationDetailsHeardAboutUs application={data} />
      </AdminOnly>
      <Divider className="pb-24" />
      <When condition={applicantRows.length}>
        <DetailsTable header="Applicant" rowData={applicantRows} />
      </When>
      <DetailsTable
        header="Budget"
        rowData={[
          {
            label: 'Budget requested',
            value: `${formatCurrency(data.requestedBudget)}`,
          },
          {
            label: 'Budget breakdown',
            value: data.budgetBreakdown,
          },
        ]}
      />
      <DetailsTable
        header="Project Details"
        rowData={[
          {
            label: 'Project title',
            value: data.name,
          },
          {
            label: 'Short description',
            value: data.tagline,
          },
          {
            label: 'Description',
            value: data.text,
          },
          {
            label: 'Community engagement',
            value: data.communityEngagement,
          },
          {
            label: 'Timeline',
            value: data.timeline,
          },
          {
            label: 'Motivation statement',
            value: data.message,
          },
        ].concat(getAdditionalDataRows(data.additionalData, 'project'))}
      />
      <DetailsTable
        header="Impact"
        rowData={[
          {
            label: 'Expected impact',
            value: data.expectedImpact,
          },
          {
            label: 'Social categories',
            value: data.themes,
          },
          {
            label: 'Beneficiaries',
            value: data.expectedBeneficiaries,
          },
          {
            label: 'Scope',
            value: data.expectedScope,
          },
          {
            label: 'Project sustainability',
            value: data.projectSustainability,
          },
        ]}
      />
      <DetailsTable
        header="Organization Details"
        rowData={[
          {
            label: 'Organization Name',
            value: data.organisationName || '',
          },
          {
            label: 'Type',
            value: data.entityType || '',
          },
          {
            label: 'Phone',
            value: data.phone,
          },
          {
            label: 'Organization tax ID',
            value: data.organisationTaxId,
          },
          {
            label: 'Social Profile',
            value: `<a href="${data.socialProfileUrl}">${data.socialProfileUrl}</a>`,
          },
          {
            label: 'Applicant Experience',
            value: data.applicantExperience,
          },
        ].concat(getAdditionalDataRows(data.additionalData, 'organisation'))}
      />
      <DetailsTable
        header="Second Contact"
        rowData={[
          {
            label: 'Name',
            value: data.secondContactName,
          },
          {
            label: 'Phone',
            value: data.secondContactPhone,
          },
          {
            label: 'Email',
            value: data.secondContactEmail,
          },
        ]}
      />
    </>
  )
}
