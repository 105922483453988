import { useRef } from 'react'
import { Button, Text, CxDialog } from '@changex/design-system'

type ConfirmationModalProps = {
  setOpen: (boolean) => void
  onClick: () => void
  open: boolean
  text: string
  okText: string
}
export function ConfirmationModal({
  open,
  setOpen,
  onClick,
  text,
  okText,
}: ConfirmationModalProps) {
  const cancelButtonRef = useRef(null)
  return (
    <CxDialog open={open} onClose={setOpen} initialFocus={cancelButtonRef}>
      <CxDialog.Panel>
        <CxDialog.Body>
          <div className="mt-2 p-3">
            <Text>{text}</Text>
          </div>
        </CxDialog.Body>
        <CxDialog.Footer>
          <Button
            weight="primary"
            className="bg-red-600 hover:bg-red-500 sm:ml-3 sm:w-auto"
            onClick={onClick}
          >
            {okText}
          </Button>
          <Button
            weight="tertiary"
            onClick={() => setOpen(false)}
            ref={cancelButtonRef}
          >
            No
          </Button>
        </CxDialog.Footer>
      </CxDialog.Panel>
    </CxDialog>
  )
}
