// TODO: Move to "shared/map"
export function toLatLng(point) {
  if (!point) {
    return
  }
  return { lat: point[0], lng: point[1] }
}

export const toLatLngs = (points) => {
  return points.map((point) => toLatLng(point))
}

export const toPoint = ({ lat, lng }) => {
  return [lat, lng]
}

export const toPoints = (latLngs) => {
  return latLngs.map((latLng) => toPoint(latLng))
}

export const getPointsFromPolygon = (polygon) => {
  return polygon
    .getPath()
    .getArray()
    .map((latLng) => toPoint(latLng.toJSON()))
}

export const getPointFromMarker = (marker) => {
  return toPoint(marker.getPosition().toJSON())
}

export const getCenterPointFromCircle = (circle) => {
  return toPoint(circle.getCenter().toJSON())
}

export const getRadiusFromCircle = (circle) => {
  return circle.getRadius()
}

export const DEFAULT_CENTER_LAT_LNG = {
  lat: 0,
  lng: 0,
}

export const METRES_PER_KM = 1000

export const DRAWING_COLOR = '#B91C1C'
