import {
  formatCurrency,
  MapCard as DSMapCard,
  MapCardBody,
  MapCardHeaderImage,
  MapCardTitle,
  MapCardProfile,
} from '@changex/design-system'
import { LocationMapApplication } from '@features/funds/types'
import { Solution } from '@types'
import { Dispatch, SetStateAction } from 'react'
import { Badge } from '@changex/design-system'
import { utils } from '@features/funds/utils'

type MapCardProps = {
  application: LocationMapApplication
  solution: Solution
  open: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
}

const MapCard: React.FC<MapCardProps> = ({
  application,
  solution,
  open,
  setIsOpen,
}) => {
  return (
    <DSMapCard open={open} setIsOpen={setIsOpen}>
      <MapCardHeaderImage src={solution.headerImageUrl!} />
      <MapCardBody>
        <div className="py-2">
          <MapCardTitle
            url={`https://changex.org/gl/${solution.slug}/${application.locationSlug}`}
          >
            {application.name}
          </MapCardTitle>
        </div>

        <div className="flex justify-between py-2">
          <div className="flex flex-col">
            <div className="text-sm text-gray-700 opacity-70">
              Funding allocated
            </div>
            <div className="text-gray-900">
              {formatCurrency(
                application.fundingCents / 100,
                application.fundingCurrency
              )}
            </div>
          </div>
          <div className="flex flex-col items-end">
            <div>
              <Badge variant={utils.getBadgeStatus(application.state)}>
                {utils.getStatusLabel(application.state)}
              </Badge>
            </div>
            {application.impactData.beneficiaries && (
              <div className="mt-2 text-sm text-gray-700 opacity-70">
                {new Intl.NumberFormat().format(
                  application.impactData.beneficiaries
                )}{' '}
                people impacted
              </div>
            )}
          </div>
        </div>

        <div className="py-2">
          <MapCardProfile
            avatarUrl={application.applicantPhotoUrl}
            name={application.applicantName}
            appliedAt={application.submittedAt}
          />
        </div>
      </MapCardBody>
    </DSMapCard>
  )
}

export default MapCard
