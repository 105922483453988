import { Button, Input, Selector, Text } from '@changex/design-system'
import { useMemo, useState } from 'react'
import styled from 'styled-components'
import { XIcon } from '@heroicons/react/solid'
import { useApplicationsQueryParams } from '../../hooks/use-applications-query-params'
import { omit } from 'lodash'
import { useDebounce } from 'react-use'
import { useFund } from '../../../../shared/providers'
import { FundWithAggregates } from '@types'
import TextConfirmationModal from 'components/text-confirmation-modal'
import { useBulkRejectApplications } from 'features/funds/components/applications-list/application/approval/hooks/use-bulk-reject-applications'

const StyledSelector = styled(Selector)`
  div {
    margin-top: 0;
  }

  ul {
    margin-top: 0.5rem;
  }
`

type SortCriteria = { id: string; label: string }

const ApplicationsFilters = ({
  fund,
  sortCriteria,
}: {
  fund: FundWithAggregates
  sortCriteria: readonly SortCriteria[]
}) => {
  const [applicationsQueryParams, setApplicationsQueryParams] =
    useApplicationsQueryParams()
  // Keep the name email search query in state, so it can be debounced
  const [nameEmailFilter, setNameEmailFilter] = useState(
    applicationsQueryParams.q
  )

  const handleResetFilters = () => {
    setNameEmailFilter('')
    setApplicationsQueryParams({
      q: null,
      solutionId: null,
    })
  }

  const handleNameEmailFilter = (event) => {
    setNameEmailFilter(event.target.value)
  }

  useDebounce(
    () => {
      setApplicationsQueryParams({ q: nameEmailFilter })
    },
    500,
    [nameEmailFilter]
  )

  const areFiltersApplied = useMemo(
    () =>
      Object.values(
        omit(applicationsQueryParams, ['tab', 'page', 'sort'])
      ).some(Boolean),
    [applicationsQueryParams]
  )

  return (
    <div className="flex items-center gap-x-4 py-4">
      <div>
        <Input
          ariaLabel="Filter by name, email, organisation..."
          className="w-96"
          onChange={handleNameEmailFilter}
          placeholder="Filter by name, email, organisation..."
          type="text"
          value={nameEmailFilter ?? ''}
        />
      </div>
      <div className="flex items-center">
        <StyledSelector
          noneSelectedButtonLabel="Idea"
          onChange={(id: string) => {
            setApplicationsQueryParams({ solutionId: id })
          }}
          options={fund.solutions
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((solution) => ({
              id: solution.id,
              label: solution.name,
            }))}
          value={applicationsQueryParams.solutionId ?? undefined}
          filterOptions={{
            enabled: true,
            fluid: {
              width: true,
            },
            placeholder: 'Filter ideas by name',
          }}
        />
      </div>
      <div className="flex items-center">
        <Text className="z-8 mr-1 whitespace-nowrap pl-4" variant="light">
          Sort by
        </Text>
        <StyledSelector
          onChange={(option) => setApplicationsQueryParams({ sort: option })}
          options={sortCriteria}
          value={applicationsQueryParams.sort ?? sortCriteria[0].id}
        />
      </div>
      <div>
        {areFiltersApplied ? (
          <Button
            weight="tertiary"
            className="border-0 text-red-600 shadow-none"
            onClick={handleResetFilters}
          >
            <XIcon className="h-5 w-5 text-red-600" />
            Clear filters
          </Button>
        ) : null}
      </div>
    </div>
  )
}

const RejectAllAction = ({ fund }: { fund: FundWithAggregates }) => {
  const bulkRejectApplications = useBulkRejectApplications(fund)
  const [modalOpen, setModalOpen] = useState(false)

  return (
    <>
      <Button
        className="border-red-600 py-1 font-normal text-red-600 hover:border-red-700"
        weight="tertiary"
        onClick={() => setModalOpen(true)}
      >
        Reject all
      </Button>
      <TextConfirmationModal
        open={modalOpen}
        onClose={setModalOpen}
        titleText="Reject all"
        matchText={'REJECT'}
        onClick={() => {
          bulkRejectApplications.mutate()
          setModalOpen(false)
        }}
      >
        <p className="text-base font-normal text-gray-900">
          This will reject all applications in the open grants inbox. Make sure
          you have first allocated any that you want to accept into the fund.
        </p>
        <strong>This action can't be undone.</strong>
      </TextConfirmationModal>
    </>
  )
}

function ApplicationsActions({
  sortCriteria,
}: {
  sortCriteria: readonly SortCriteria[]
}) {
  const fund = useFund()
  const [applicationsQueryParams] = useApplicationsQueryParams()
  const isOpenGrantInboxTab =
    fund.options.type !== 'replication' && applicationsQueryParams?.tab === 0

  return (
    <div className="max-w-changex mx-auto bg-white">
      <div className="max-w-changex mx-auto flex items-center justify-between bg-white">
        <ApplicationsFilters fund={fund} sortCriteria={sortCriteria} />
        {isOpenGrantInboxTab && <RejectAllAction fund={fund} />}
      </div>
    </div>
  )
}

export default ApplicationsActions
