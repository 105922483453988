import { TDraftOpenGrantApplication } from '../../../types'
import BaseApplicationOwner from '../../base-application/base-application-owner'

type TProps = {
  data: TDraftOpenGrantApplication
}

const DraftApplicationOwner: React.FC<TProps> = ({ data }) => {
  return (
    <BaseApplicationOwner avatarUrl={data.owner.avatarUrl || ''}>
      <BaseApplicationOwner.HeaderSection
        fullName={data.owner.fullName || ''}
        isOpenGrant={true}
      />
      <BaseApplicationOwner.ContactDetails
        email={data.owner.email || ''}
        locationName={data.locationName}
      />
    </BaseApplicationOwner>
  )
}

export default DraftApplicationOwner
