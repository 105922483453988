import { LoadingIcon } from '@changex/design-system'
import Skeleton from '../applications-list/skeleton'
import classNames from 'classnames'
import { UseQueryResult } from '@tanstack/react-query'
import { Result } from 'shared/hooks/json-api/use-json-api-query'
import DraftApplication from './draft-application/draft-application'
import { TDraftOpenGrantApplication } from '@features/funds/types'

function DraftApplicationsList({
  applicationsQuery,
}: {
  applicationsQuery: UseQueryResult<Result<TDraftOpenGrantApplication[]>>
}) {
  return (
    <div
      data-testid="applications-list"
      className={classNames(
        'relative',
        applicationsQuery.isFetching && 'opacity-80'
      )}
    >
      {applicationsQuery.isLoading ? (
        <div data-testid="applications-list-loader">
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </div>
      ) : (
        <div className="mt-5">
          {applicationsQuery.data?.results.map((application) => (
            <DraftApplication application={application} key={application.id} />
          ))}
        </div>
      )}
      {applicationsQuery.isFetching && (
        <div
          data-testid="applications-list-refreshing"
          className="absolute bottom-2 left-2 flex gap-x-2 bg-white p-2 text-sm opacity-80"
        >
          <LoadingIcon /> Applications list refreshing...
        </div>
      )}
    </div>
  )
}

export default DraftApplicationsList
