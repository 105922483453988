import { ApplicationApprovalStageProps } from './application-approval.types'
import { ApplicationApprovalRejectButton } from './components/application-approval-reject-button'
import { useRejectApplication } from './hooks/use-reject-application'
import { APPLICATION_APPROVAL_REJECT_TOOLTIP } from '@features/funds/components/applications-list/application/approval/application-approval.constants'
import { ApplicationPaymentAccountsStatus } from './components/ApplicationPaymentAccountsStatus'
import { ApplicationPayment } from './application-payment'
import { ApplicationApprovalStageSuccessTickSolution } from './components/application-approval-stage-success-tick-solution'
import { TAllocationType } from 'features/funds/types'

function ApplicationApprovalStageApprovedInner({
  application,
}: ApplicationApprovalStageProps) {
  const reject = useRejectApplication(application)

  return (
    <>
      <div className="flex items-center gap-x-3">
        <ApplicationPaymentAccountsStatus
          paymentAccounts={
            application?.location?.payments?.location.paymentAccounts
          }
          onlyFailedPaymentsForAllocation={
            application?.location?.payments?.location?.status
              .onlyFailedPaymentsForAllocation
          }
        />
        <ApplicationApprovalRejectButton
          tooltip={APPLICATION_APPROVAL_REJECT_TOOLTIP}
          onClick={reject.mutate}
          isLoading={reject.isLoading}
        />
      </div>
      <div className="flex items-center gap-x-3">
        <ApplicationApprovalStageSuccessTickSolution
          application={application}
        />
        <ApplicationPayment
          allocationType={TAllocationType.Seed}
          amount={
            application?.location?.payments?.location.budgets.approved
              .allocationBalances.seed
          }
          application={application}
        />
        <ApplicationPayment
          allocationType={TAllocationType.Solution}
          amount={
            application?.location?.payments?.solution?.budgets.approved.balance
          }
          application={application}
        />
      </div>
    </>
  )
}

// Wrap in strip provider
export function ApplicationApprovalStageApproved({
  application,
}: ApplicationApprovalStageProps) {
  return <ApplicationApprovalStageApprovedInner application={application} />
}
