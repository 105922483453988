import { Text } from '@changex/design-system'

export function ApplicationsCount({ count }: { count: number }) {
  if (count !== null) {
    return (
      <Text variant="light">
        {count} application
        {count === 1 ? '' : 's'} found
      </Text>
    )
  }
  return null
}
