import { WithTooltip, Heading, Dropdown } from '@changex/design-system'
import StatusLegend from '../status-legend'
import { useCallback } from 'react'
import { useFund } from 'shared/providers'
import { useApplicationDownload } from '@features/funds/hooks/use-application-download'
import toast from 'react-hot-toast'

const ERROR_DOWNLOADING_MESSAGE = 'There was an error requesting the download'

function ApplicationsHeader({
  totalApplications,
}: {
  totalApplications: number
}) {
  const fund = useFund()
  const applicationDownloadMutation = useApplicationDownload()

  const handleDownloadAll = useCallback(() => {
    applicationDownloadMutation.mutate(
      {
        allApplications: true,
        fundId: fund.id,
      },
      {
        onSuccess: () => {
          toast.success(
            'An export of applicants will be emailed to you in the next few minutes.',
            { duration: 5000 }
          )
        },
        onError: () => {
          toast.error(ERROR_DOWNLOADING_MESSAGE, { duration: 5000 })
        },
      }
    )
  }, [applicationDownloadMutation, fund])

  const handleDownloadOpenGrant = useCallback(() => {
    applicationDownloadMutation.mutate(
      { fundId: fund.id },
      {
        onSuccess: () => {
          toast.success(
            'An export of open grant applicants will be emailed to you in the next few minutes.',
            { duration: 5000 }
          )
        },
        onError: () => {
          toast.error(ERROR_DOWNLOADING_MESSAGE, { duration: 5000 })
        },
      }
    )
  }, [applicationDownloadMutation, fund])

  return (
    <div className="bg-copper-50">
      <div className="max-w-changex mx-auto py-2.5">
        <div className="flex justify-between">
          <Heading className="flex items-center">
            Applications
            <WithTooltip tooltip="No. of applications in the open grants inbox, replication inbox, allocated, succeeded, approved, paid seed, impact, paid impact, failed, failed impact, rejected, and refunded state.">
              <span className="ml-3 border-b border-dashed border-gray-300 text-lg font-light text-gray-400">
                ({totalApplications})
              </span>
            </WithTooltip>
          </Heading>
          <div className="flex">
            <StatusLegend />
            <Dropdown
              title="Download"
              disabled={applicationDownloadMutation.isLoading}
              items={[
                {
                  type: 'button',
                  id: 'all',
                  title: 'Download all applicants',
                  onClick: handleDownloadAll,
                },
                {
                  type: 'button',
                  id: 'open-grant',
                  title: 'Download open grant applicants',
                  onClick: handleDownloadOpenGrant,
                },
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ApplicationsHeader
