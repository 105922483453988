const defaultTheme = require('tailwindcss/defaultTheme')
/** @type {import('tailwindcss').Config} */

module.exports = {
  content: ['./src/**/*.{js,jsx,ts,tsx}', './public/index.html'],
  theme: {
    extend: {
      colors: {
        copper: {
          50: '#f7f2ef',
          100: '#f1e8e3',
          200: '#e2d0c6',
          300: '#cfb1a2',
          400: '#bb8d7c',
          500: '#ad7362',
          600: '#a06256',
          700: '#854f49',
          800: '#6d433f',
          900: '#593735',
          950: '#2f1b1b',
        },
        green: {
          50: '#f3fbf2',
          100: '#e4f8e0',
          200: '#c9f0c2',
          300: '#9ee293',
          400: '#6acc5c',
          500: '#4bc03a',
          600: '#359227',
          700: '#2c7322',
          800: '#265c1f',
          900: '#204b1c',
          950: '#0c290a',
        },
        teal: {
          50: '#F2FFFE',
          100: '#D8F7F4',
          200: '#A8E7E1',
          300: '#69D0C9',
          400: '#4EBBB7',
          500: '#34ADA5',
          600: '#1B968D',
          700: '#107C79',
          800: '#005E5B',
          900: '#1C4445',
          950: '#0B2628',
        },
        coral: {
          50: '#FDF2F4',
          100: '#FCE3E8',
          200: '#FBCBD2',
          300: '#F7ABB1',
          400: '#f07e82',
          500: '#E3514F',
          600: '#D13E33',
          700: '#B03727',
          800: '#903523',
          900: '#7B3624',
          950: '#3F1B0D',
        },
      },
      fontFamily: {
        sans: ['Circular Std', ...defaultTheme.fontFamily.sans],
      },
      maxWidth: {
        changex: '1170px',
      },
      height: {
        header: '70px',
      },
      rotate: {
        22: '22deg',
      },
    },
  },
  plugins: [require('@tailwindcss/forms')],
  safelist: [
    'max-w-4xl',
    'max-w-md',
    'py-1',
    'py-2',
    'py-3',
    'py-4',
    'py-5',
    'bg-teal-800',
  ],
}
