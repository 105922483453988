import { FC, useMemo } from 'react'
import {
  APPLICATIONS_TAB_LIST,
  APPLICATIONS_TABS_TO_SHOW_COUNT,
} from '@features/funds/constants'
import { Badge, Dropdown, DropdownItem } from '@changex/design-system'
import { utils } from '@features/funds/utils'
import { FundWithAggregates } from '@types'
import { Link } from 'react-router-dom'
import { useOpenGrantDraftApplicationList } from '@features/funds/hooks/use-open-grant-draft-application-list'
import { useApplicationsQueryParams } from '@features/funds/hooks/use-applications-query-params'

const normalizeLabel = (label: string) => {
  if (label === 'paidSeed') return 'paid_seed'
  if (label === 'paidImpact') return 'paid_impact'
  return label
}

const TabLabel: React.FC<{
  label: string
  badgeVariant: string
  count: number
  className?: string
}> = ({ label, badgeVariant, count, className = '' }) => (
  <div className={className}>
    <span>{label}</span>
    {count > 0 && (
      <Badge variant={badgeVariant} className="ml-1.5">
        {count}
      </Badge>
    )}
  </div>
)

type ApplicationTabsProps = {
  fund: FundWithAggregates
}

const LinkToTab = ({
  tabKey,
  href,
  label,
  count,
  className,
  selected = false,
}: {
  tabKey: string
  href: string
  label: string
  count: number
  className?: string
  selected?: boolean
}) => {
  className = selected ? `${className} border-gray-400 text-inherit` : className

  return (
    <Link to={href} className={className}>
      <TabLabel
        label={label}
        badgeVariant={utils.getBadgeStatus(normalizeLabel(tabKey))}
        count={count}
      />
    </Link>
  )
}

export const ApplicationsTabs: FC<ApplicationTabsProps> = ({ fund }) => {
  const [applicationQueryParams] = useApplicationsQueryParams()
  const selectedIndex = useMemo(
    () => applicationQueryParams.tab ?? -1,
    [applicationQueryParams]
  )

  const draftApplications = useOpenGrantDraftApplicationList(fund.id)

  const dropdownItems: DropdownItem[] = APPLICATIONS_TAB_LIST(fund.options.type)
    .slice(APPLICATIONS_TABS_TO_SHOW_COUNT)
    .map((tab, index) => ({
      type: 'link',
      id: tab.key,
      href: `/funds/${fund.id}/applications?tab=${
        APPLICATIONS_TABS_TO_SHOW_COUNT + index
      }&page=1`,
      title: (
        <TabLabel
          label={tab.label}
          badgeVariant={utils.getBadgeStatus(normalizeLabel(tab.key))}
          count={fund.aggregates![tab.key]}
        />
      ),
    }))

  if (fund.options.type !== 'replication' && draftApplications.isSuccess) {
    dropdownItems.push({
      id: 'draft',
      type: 'link',
      href: `/funds/${fund.id}/draft-applications`,
      title: (
        <TabLabel
          label="Draft"
          badgeVariant="basic"
          count={draftApplications?.data?.meta?.stats.total.count || 0}
          className="text-gray-500"
        />
      ),
    })
  }
  return (
    <>
      <div className="sticky top-12 z-10 bg-white shadow-sm">
        <div className="max-w-changex mx-auto">
          <ul className="-mb-px flex space-x-8">
            {APPLICATIONS_TAB_LIST(fund.options.type)
              .slice(0, APPLICATIONS_TABS_TO_SHOW_COUNT)
              .map((tab, index) => (
                <li key={tab.key}>
                  <LinkToTab
                    tabKey={tab.key}
                    href={`/funds/${fund.id}/applications?tab=${index}&page=1`}
                    label={tab.label}
                    count={fund.aggregates![tab.key]}
                    selected={selectedIndex === index}
                    className="flex whitespace-nowrap border-b-2 border-transparent py-3 px-1 text-sm font-medium text-gray-500 hover:text-gray-800 focus:ring-0"
                  />
                </li>
              ))}
            <li className="font-sm ml-2 pt-1 font-medium text-slate-500">
              <Dropdown
                title="More"
                variant="minimal"
                className="shadow:none border-transparent bg-transparent focus:border-transparent focus:ring-0"
                items={dropdownItems}
                linkAs={Link}
              />
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}

export default ApplicationsTabs
