import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { QueryInfrastructure } from '@infrastructure'
import '@config/styles'
import '@config/mocks'

import FundApplicationsPage from 'app/funds/[id]/applications/page'
import FundDraftApplicationsPage from 'app/funds/[id]/draft-applications/page'
import NotFoundContainer from './features/not-found/containers/not-found.container'

import { QueryParamInfrastructure } from './infrastructure/query-param.infrastructure'
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorPage } from '@features/not-found/pages/error.page'
import ImpactFeedPage from 'app/funds/[id]/feed/page'
import MapPage from 'app/funds/[id]/map/page'
import PhotosPage from 'app/funds/[id]/photos/page'
import DashboardPage from 'app/funds/[id]/dashboard/page'
import TeamMembersPage from 'app/funds/[id]/settings/team/page'
import PaymentHistoryPage from 'app/funds/[id]/settings/payment-history/page'
import GeneralPage from 'app/funds/[id]/settings/general/page'
import ChangelogPage from 'app/funds/[id]/settings/log/page'

export function App() {
  return (
    <React.StrictMode>
      {/* @ts-ignore there is an issue with the types currently related to https://github.com/bvaughn/react-error-boundary/issues/111#issuecomment-1262939766 */}
      <ErrorBoundary fallback={<ErrorPage />}>
        <QueryInfrastructure>
          <BrowserRouter>
            <QueryParamInfrastructure>
              <Routes>
                <Route
                  path="/funds/:fundId/applications"
                  element={<FundApplicationsPage />}
                />
                <Route
                  path="/funds/:fundId/applications/:applicationId"
                  element={<FundApplicationsPage />}
                />
                <Route
                  path="/funds/:fundId/draft-applications"
                  element={<FundDraftApplicationsPage />}
                />
                <Route
                  path="/funds/:fundId/feed"
                  element={<ImpactFeedPage />}
                />
                <Route path="/funds/:fundId/photos" element={<PhotosPage />} />
                <Route path="/funds/:fundId/map" element={<MapPage />} />
                <Route
                  path="/funds/:fundId/dashboard"
                  element={<DashboardPage />}
                />
                <Route
                  path="/funds/:fundId/settings/team"
                  element={<TeamMembersPage />}
                />
                <Route
                  path="/funds/:fundId/settings/payment-history"
                  element={<PaymentHistoryPage />}
                />
                <Route
                  path="/funds/:fundId/settings/general"
                  element={<GeneralPage />}
                />
                <Route
                  path="/funds/:fundId/settings/log"
                  element={<ChangelogPage />}
                />
                <Route path="*" element={<NotFoundContainer />} />
              </Routes>
            </QueryParamInfrastructure>
          </BrowserRouter>
        </QueryInfrastructure>
      </ErrorBoundary>
    </React.StrictMode>
  )
}
