import { useRef, useState } from 'react'
import { Button, CxDialog } from '@changex/design-system'

type TextConfirmationModalProps = {
  open: boolean
  onClose: (boolean) => void
  onClick: () => void
  titleText: string
  matchText: string
  children: React.ReactNode
}

const TextConfirmationModal = ({
  open,
  onClose,
  onClick,
  titleText,
  matchText,
  children,
}: TextConfirmationModalProps) => {
  const [input, setInput] = useState('')
  const cancelButtonRef = useRef(null)
  return (
    <CxDialog open={open} onClose={onClose} initialFocus={cancelButtonRef}>
      <CxDialog.Panel className="">
        <CxDialog.Title>{titleText}</CxDialog.Title>
        <CxDialog.Body>
          {children}
          <p className="mt-2 text-base font-normal text-gray-900">
            Type <strong>{matchText}</strong> to confirm:
          </p>
          <input
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            className="mt-2 block w-full rounded border border-gray-300 p-2"
          />
        </CxDialog.Body>
        <CxDialog.Footer>
          <Button
            weight="primary"
            onClick={onClick}
            disabled={input !== matchText}
            className="bg-red-600 enabled:hover:bg-red-700 sm:ml-3 sm:w-auto"
          >
            Reject all
          </Button>
          <Button
            weight="tertiary"
            onClick={() => onClose(false)}
            ref={cancelButtonRef}
          >
            Cancel
          </Button>
        </CxDialog.Footer>
      </CxDialog.Panel>
    </CxDialog>
  )
}

export default TextConfirmationModal
