import { Skeleton } from '@changex/design-system'
import Layout from 'app/funds/[id]/layout'
import { Nullable } from '@types'
import { useParams } from 'react-router-dom'
import { useQueryParams, StringParam } from 'use-query-params'

import { useFeedQuery } from 'features/funds/hooks/use-feed-query'
import FeedList from 'features/funds/components/feed-list/feed-list'
import { Feed, SolutionsAndCategoriesFilter } from 'features/funds/types'
import { useCurrentUser } from 'shared/providers'
import { FeedFilters } from 'features/funds/components/feed-filters'
import { useEffect, useState } from 'react'
import { useSolutionsAndSocialCategoriesQuery } from 'features/funds/hooks/use-solutions-social-categories-query'
import { utils } from 'features/funds/utils'

function ImpactFeedPageContent() {
  const [queryFilters, setQueryFilters] = useQueryParams({
    'filter[entry_type]': StringParam,
    'filter[state]': StringParam,
    'filter[solution_id]': StringParam,
    'filter[social_category_ids]': StringParam,
    'page[number]': StringParam,
  })
  const [filters, setFilters] = useState<Record<string, Nullable<string>>>(
    queryFilters as Record<string, string>
  )
  const { fundId } = useParams<{ fundId: string }>()
  const feedQuery = useFeedQuery<Feed[]>(fundId, filters)
  const currentUser = useCurrentUser()
  const filtersQuery =
    useSolutionsAndSocialCategoriesQuery<SolutionsAndCategoriesFilter>(fundId)
  const solutions = utils.getSolutionListByFiltering(
    filtersQuery.data?.results.solutions || []
  )

  useEffect(() => {
    setQueryFilters(
      Object.values(filters).filter(Boolean).length > 0
        ? Object.entries(filters).reduce(
            (f, [key, value]) => ({ ...f, [key]: value || null }),
            {}
          )
        : {
            'filter[entry_type]': null,
            'filter[state]': null,
            'filter[solution_id]': null,
            'filter[social_category_ids]': null,
            'page[number]': null,
          }
    )
  }, [filters, setQueryFilters])

  const handleFilterChange = (name: string, value: string) => {
    setFilters((oldFilters) => ({
      ...oldFilters,
      [name]: value,
      'page[number]': name !== 'page[number]' ? null : filters['page[number]'],
    }))
  }

  const handlePagination = (pagination) => {
    window.scrollTo({ top: -10 })
    setFilters((oldFilters) => ({
      ...oldFilters,
      'page[number]': pagination.page.toString(),
    }))
  }

  const handleResetFilters = () => {
    setFilters({})
  }

  return (
    <>
      <div className="bg-copper-50 sticky top-12 z-20">
        <div className="max-w-changex mx-auto flex flex-col gap-y-4 py-2.5">
          <FeedFilters
            filters={filters}
            onFilter={handleFilterChange}
            onReset={handleResetFilters}
            socialCategories={filtersQuery.data?.results.socialCategories || []}
            solutions={solutions}
          />
        </div>
      </div>
      <div className="max-w-changex mx-auto mt-6">
        <div className="flex flex-col gap-y-6">
          {!feedQuery.isFetching &&
          !filtersQuery.isFetching &&
          feedQuery.data?.results.length === 0 ? (
            <div className="max-w-changex mx-auto my-20 text-center">
              <p className="mt-4">
                There hasn't been any activity here as yet, come back soon.
              </p>{' '}
            </div>
          ) : (
            <>
              {feedQuery.isFetching || filtersQuery.isFetching ? (
                <>
                  <Skeleton className="h-6 w-[200px]" />
                  <div className="flex max-w-screen-xl items-start gap-x-20">
                    <div className="m-auto flex flex-1 flex-col gap-y-4">
                      <Skeleton className="h-6" />
                      <Skeleton className="h-[250px]" />
                      <Skeleton className="h-[250px]" />
                      <Skeleton className="h-[250px]" />
                      <Skeleton className="h-[250px]" />
                      <div className="flex justify-between">
                        <Skeleton className="h-10 w-[100px]" />
                        <div className="flex gap-x-4">
                          <Skeleton className="h-10 w-10" />
                          <Skeleton className="h-10 w-10" />
                          <Skeleton className="h-10 w-10" />
                        </div>
                        <Skeleton className="h-10 w-[100px]" />
                      </div>
                    </div>
                    <div className="flex-0 flex w-1/3 flex-col gap-4">
                      <Skeleton className="h-6" />
                      <Skeleton className="h-6" />
                      <Skeleton className="h-6" />
                      <Skeleton className="h-6" />
                    </div>
                  </div>
                </>
              ) : (
                <FeedList
                  currentUser={currentUser}
                  data={feedQuery.data?.results || []}
                  pagination={{
                    currentPage: filters['page[number]'] || '1',
                    meta: feedQuery.data?.meta,
                    onFilter: handlePagination,
                  }}
                  solutions={filtersQuery.data?.results.solutions || []}
                />
              )}
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default function ImpactFeedPage() {
  return (
    <Layout>
      <ImpactFeedPageContent />
    </Layout>
  )
}
