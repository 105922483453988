import { TDraftOpenGrantApplication } from '../../../types'
import { useCurrencyFormatter } from '../../../../../shared/currency/hooks/use-currency-formatter'
import { ApplicationInfoDefinitionList } from '../../applications-list/application/application-info-definition-list'
import { Badge } from '@changex/design-system'

type Props = {
  data: TDraftOpenGrantApplication
}

const DraftBadge = () => {
  return <Badge variant="basic">Draft</Badge>
}

function DraftApplicationInfo({ data }: Props) {
  const formatCurrency = useCurrencyFormatter()

  return (
    <ApplicationInfoDefinitionList
      definitions={[
        {
          term: 'Status',
          definition: <DraftBadge />,
          testId: 'status',
        },
        {
          term: 'Project',
          definition: data.name,
        },
        {
          term: 'Requested',
          definition: formatCurrency(data?.requestedBudget),
        },
      ]}
    />
  )
}

export default DraftApplicationInfo
