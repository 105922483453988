import { useState } from 'react'
import {
  FundApplication,
  UserFundRole,
  TAllocationType,
  PaymentAccount,
} from 'features/funds/types'
import { ApplicationApprovalAcceptButton } from 'features/funds/components/applications-list/application/approval/components/application-approval-accept-button'
import { ApplicationApprovalAcceptButtonWithDropdown } from 'features/funds/components/applications-list/application/approval/components/application-approval-accept-button-with-dropdown'
import { ApplicationApprovalConfirmPayment } from 'features/funds/components/applications-list/application/approval/application-approval-confirm-payment'
import { DropdownItem } from '@changex/design-system'
import { ConfirmationModal } from 'components/confirmation-modal'
import { usePayApplication } from './hooks/use-pay-application'
import { useManuallyPayApplication } from './hooks/use-manually-pay-application'
import { useStripePayApplication } from './hooks/use-stripe-pay-application'
import { utils } from 'features/funds/utils'
import { useFund } from 'shared/providers'
import { useFundCurrency } from 'shared/currency/hooks/use-fund-currency'

type ApplicationPaymentProps = {
  allocationType: TAllocationType
  amount?: number
  application: FundApplication
}

export function ApplicationPayment({
  allocationType,
  amount,
  application,
}: ApplicationPaymentProps) {
  const fund = useFund()
  const fundCurrency = useFundCurrency()

  const [selectedPaymentAccount, setSelectedPaymentAccount] =
    useState<null | PaymentAccount>(null)

  const pay = usePayApplication(application, allocationType)
  const manuallyPay = useManuallyPayApplication(application, allocationType)
  const stripePay = useStripePayApplication(application, allocationType)

  const solutionPayment = allocationType === TAllocationType.Solution

  const paymentAccounts = solutionPayment
    ? application?.location?.payments?.solution?.paymentAccounts
    : application?.location?.payments?.location.paymentAccounts

  const allocationLabel = solutionPayment ? 'SI' : allocationType

  const alternativePaymentOptions = (paymentAccounts): DropdownItem[] => {
    const options: DropdownItem[] = []

    paymentAccounts?.forEach((paymentAccount) => {
      let title = `Pay with ${paymentAccount.paymentPlatformAccountName}`
      if (paymentAccount.currency && paymentAccount.currency !== fundCurrency) {
        title = `${title} (${paymentAccount.currency})`
      }
      options.push({
        type: 'button',
        id: `payment-account-${paymentAccount.id}`,
        title: title,
        onClick: () =>
          paymentAccount.accountType === 'stripe'
            ? stripePay.mutate()
            : setSelectedPaymentAccount(paymentAccount),
      })
    })

    options.push({
      type: 'button',
      id: 'manual',
      title: 'Mark as paid manually',
      onClick: () => manuallyPay.mutate(),
    })

    return options
  }

  // NOTE: We'll take the first payment account in the list as we'll assume the
  //       API orders them by priority.
  const paymentAccount = paymentAccounts && paymentAccounts[0]

  const modalText = (paymentAccount?) => {
    if (!paymentAccount) return ''

    if (paymentAccount.currency !== fundCurrency) {
      return `The currency of this payment account (${
        paymentAccount.currency
      }) is not the same as the fund (${fundCurrency}), the payment amount (in the fund currency) will be converted using the latest rate into the payment account currency - are you sure you wish to continue to make this payment via ${utils.titleize(
        paymentAccount.accountType
      )}?`
    }

    return `Are you sure you wish to make this payment via ${utils.titleize(
      paymentAccount.accountType
    )}?`
  }

  const confirmPayment = (paymentAccount) => {
    paymentAccount.accountType === 'stripe'
      ? stripePay.mutate()
      : setSelectedPaymentAccount(paymentAccount)
  }

  if (
    fund.userRole !== UserFundRole.Admin ||
    !amount ||
    (solutionPayment &&
      (!application.location.payments?.solution ||
        utils.solutionAllocationPaid(application.location.payments?.solution)))
  ) {
    return null
  }

  const buttonTitle = () => {
    let title = `Pay ${allocationLabel}`

    if (paymentAccount) {
      title = `${title} - ${paymentAccount.paymentPlatformAccountName}`
      title =
        paymentAccount.currency && paymentAccount.currency !== fundCurrency
          ? `${title}, ${paymentAccount.currency}`
          : title
    }

    return title
  }

  const status = solutionPayment
    ? application?.location?.payments?.solution?.status
    : application?.location?.payments?.location.status

  if (status?.enabled) {
    return (
      <>
        <ApplicationApprovalAcceptButtonWithDropdown
          acceptActionText={buttonTitle()}
          onClick={() => confirmPayment(paymentAccount)}
          tooltip={status?.description}
          isLocked={!paymentAccount || !status?.enabled || false}
          isLoading={
            pay.isLoading || stripePay.isLoading || manuallyPay.isLoading
          }
          amount={amount}
          items={alternativePaymentOptions(paymentAccounts?.slice(1))}
        />
        <ConfirmationModal
          open={!!selectedPaymentAccount}
          onClick={() => {
            pay.mutate(selectedPaymentAccount)
            setSelectedPaymentAccount(null)
          }}
          setOpen={setSelectedPaymentAccount}
          text={modalText(selectedPaymentAccount)}
          okText="Yes"
        />
        {stripePay.stripePaymentIntent && (
          <ApplicationApprovalConfirmPayment
            onClose={() => stripePay.setStripePaymentIntent(null)}
            paymentIntent={stripePay.stripePaymentIntent}
          />
        )}
      </>
    )
  }

  return (
    <ApplicationApprovalAcceptButton
      acceptActionText={`Pay ${allocationLabel}`}
      isLocked={true}
      tooltip={status?.description}
      amount={amount}
    />
  )
}
