import { useJsonApiQuery } from '../../../shared/hooks/json-api/use-json-api-query'
import { DRAFT_APPLICATIONS_PAGE_SIZE } from '../constants'
import { TDraftOpenGrantApplication } from '../types'

export function useOpenGrantDraftApplicationList(
  fundId: string,
  keywords?: string | undefined | null,
  page?: number | null,
  sort?: string | null
) {
  return useJsonApiQuery<TDraftOpenGrantApplication[]>([
    'draft_project_applications',
    {
      filter: {
        fund_id: fundId,
        ...(keywords ? { keywords: keywords } : {}),
      },
      page: {
        size: DRAFT_APPLICATIONS_PAGE_SIZE,
        number: page,
      },
      stats: {
        total: 'count',
      },
      include: ['owner'],
      sort,
    },
  ])
}
