import { useRef, useCallback } from 'react'
import { useFormContext, useController } from 'react-hook-form'
import {
  DrawingManager,
  GoogleMapsWrapper,
  Map,
  Marker,
  Polygon,
} from '@changex/design-system'

import {
  toLatLngs,
  getPointsFromPolygon,
  getPointFromMarker,
  toLatLng,
} from 'shared/utils/map-helpers'

const DRAWING_MODES: any[] = ['marker', 'polygon'] // TODO:

const mapPolygonsValue = (polygonsValue: any) => {
  const newPoints: any = []
  for (const item of polygonsValue) {
    if (item.length > 0) {
      newPoints.push(toLatLngs(item))
    }
  }
  return newPoints
}

function PolygonTypeEditor() {
  const drawingManager = useRef(null)
  const { control } = useFormContext()
  const {
    field: { onChange: onPointsChange, value: polygonsValue },
  } = useController({
    name: 'options.locationMatch.options.polygons',
    control: control,
    defaultValue: [],
  })
  const {
    field: { onChange: onCentreChange, value: centreValue },
  } = useController({
    name: 'options.locationMatch.options.centre',
    control: control,
  })
  const paths = mapPolygonsValue(polygonsValue)
  const centerLatLng = toLatLng(centreValue)

  const handleOverlayComplete = useCallback(
    (overlay) => {
      if (overlay.type === window.google.maps.drawing.OverlayType.POLYGON) {
        const polygonOverlay = overlay.overlay
        const newCoords = getPointsFromPolygon(polygonOverlay)
        onPointsChange(polygonsValue.concat([newCoords]))

        polygonOverlay.setMap(null)
        // @ts-ignore
        drawingManager.current.setDrawingMode(null)
      } else if (
        overlay.type === window.google.maps.drawing.OverlayType.MARKER
      ) {
        const markerOverlay = overlay.overlay
        const newCenter = getPointFromMarker(markerOverlay)
        onCentreChange(newCenter)

        markerOverlay.setMap(null)
        // @ts-ignore
        drawingManager.current.setDrawingMode(null)
      }
    },
    [onCentreChange, onPointsChange, polygonsValue]
  )

  const setDrawingManagerRef = useCallback(
    (ref) => (drawingManager.current = ref),
    [drawingManager]
  )

  return (
    <GoogleMapsWrapper>
      <Map center={centerLatLng} zoom={5}>
        <DrawingManager
          setRef={setDrawingManagerRef}
          onOverlayComplete={handleOverlayComplete}
          drawingModes={DRAWING_MODES}
        />
        {paths.map((item: any, index) => (
          <Polygon
            key={`polygon-container-${index}`}
            paths={item}
            editable={true}
            onChange={(newPoints) => {
              onPointsChange(
                polygonsValue.map((polygon, idx) =>
                  idx === index ? newPoints : polygon
                )
              )
            }}
          />
        ))}
        {centerLatLng && <Marker position={centerLatLng} />}
      </Map>
    </GoogleMapsWrapper>
  )
}

export default PolygonTypeEditor
