import React from 'react'
import {
  ApplicationEntityType,
  Avatar,
  Badge,
  CopyLinkButton,
  TEntityType,
} from '@changex/design-system'
import classNames from 'classnames'

const TOOLTIP = 'Copy direct link to application'

type RootProps = {
  avatarUrl: string
  className?: string
  children: React.ReactNode
}

const BaseApplicationOwnerRoot = ({
  avatarUrl,
  className,
  children,
}: RootProps) => {
  return (
    <div className={classNames('flex flex-1', className)}>
      <div className="mr-4">
        <Avatar sourceUrl={avatarUrl} />
      </div>
      <div className="flex flex-col">{children}</div>
    </div>
  )
}

type HeaderSectionProps = {
  fullName: string
  entityType?: TEntityType
  isOpenGrant: boolean
  applicationUrl?: string
  linkSrLabel?: string
  linkTooltipHint?: string
}

const HeaderSection: React.FC<HeaderSectionProps> = ({
  fullName,
  entityType,
  isOpenGrant,
  applicationUrl,
}) => {
  const handleWrapperClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
  }

  return (
    <div className="mb-1 flex items-center gap-1.5">
      <span className="text-lg text-gray-900">{fullName}</span>
      {entityType && <ApplicationEntityType entityType={entityType} />}
      {isOpenGrant && (
        <div>
          <Badge variant="open_grant">Open grant</Badge>
        </div>
      )}
      {applicationUrl && (
        <div onClick={handleWrapperClick}>
          <CopyLinkButton
            label={TOOLTIP}
            link={applicationUrl}
            tooltipHint={TOOLTIP}
          />
        </div>
      )}
    </div>
  )
}

type ContactDetailsProps = {
  organisationName?: string
  email: string
  phone?: string
  locationName?: string
}

const ContactDetails: React.FC<ContactDetailsProps> = ({
  organisationName,
  email,
  phone,
  locationName,
}) => (
  <>
    {organisationName && (
      <span className="text-sm text-gray-700 opacity-70">
        {organisationName}
      </span>
    )}
    <span className="text-sm text-gray-700 opacity-70">{email}</span>
    {phone && (
      <span className="mt-6 flex text-sm text-gray-700 opacity-70">
        {phone}
      </span>
    )}
    {locationName && (
      <span className="mt-2 text-sm text-gray-700 opacity-70">
        {locationName}
      </span>
    )}
  </>
)

const BaseApplicationOwner = Object.assign(BaseApplicationOwnerRoot, {
  HeaderSection,
  ContactDetails,
})

export default BaseApplicationOwner
