import { BarChartTable } from '@changex/design-system'
import { Solution } from '@types'

type TIdeaAggregates = {
  solution: Solution
  total: number
  waiting: number
  inProgress: number
  funded: number
  unsuccessful: number
}

type TProps = {
  data: TIdeaAggregates[]
}

const ApplicationsPerIdea: React.FC<TProps> = ({ data }) => {
  return (
    <div className="rounded bg-white p-4">
      <BarChartTable
        title="Applications per idea"
        primaryColumnLabel="Idea"
        legend={[
          {
            label: 'Waiting',
            color: 'bg-gray-200',
            key: 'waiting',
            tooltip:
              'Applications which are on the waitlist, or waiting to be reviewed in the open grants inbox',
          },
          {
            label: 'In progress',
            color: 'bg-teal-800',
            key: 'inProgress',
            tooltip:
              'Applications which have been accepted into the fund, but have not yet received funding (replication inbox, allocated, approved, succeeded)',
          },
          {
            label: 'Funded',
            color: 'bg-green-500',
            key: 'funded',
            tooltip:
              'Applications which have received funding (replications: paid seed, impact, paid impact, failed impact; open grants: allocated, approved, succeeded, paid seed, impact, paid impact, failed impact)',
          },
          {
            label: 'Unsuccessful',
            color: 'bg-coral-200',
            key: 'unsuccessful',
            tooltip: 'Applications which have failed or been rejected',
          },
        ]}
        rows={data.map((item) => ({
          label: item.solution.id === '99' ? 'Open grants' : item.solution.name,
          total: item.total,
          stats: {
            waiting: item.waiting,
            inProgress: item.inProgress,
            funded: item.funded,
            unsuccessful: item.unsuccessful,
          },
        }))}
      />
    </div>
  )
}

export default ApplicationsPerIdea
