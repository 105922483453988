import {
  CurrencyIso,
  FinancialStat,
  Skeleton,
  Text,
} from '@changex/design-system'
import ExpandCollapse from '../../../../components/expand-collapse'
import { useState } from 'react'
import { FundType } from '@features/funds/types'
import CurrencyFilter from './currency-filter'
import { useFinancialAnalyticsQuery } from 'features/funds/hooks/use-financial-analytics-query'
import { utils } from 'features/funds/utils'
import HybridStats from './hybrid-stats'
import NonHybridStats from './non-hybrid-stats'
import FundSummary from './fund-summary'

const LoadingSkeleton = () => {
  return (
    <section className="bg-white pb-20 pt-6">
      <div className="max-w-changex relative mx-auto">
        <div className="flex justify-between">
          <h1 className="text-3xl text-teal-900">Overview</h1>
          <Skeleton className="w-[155px]" />
        </div>

        <div className="mt-6 flex items-center gap-5">
          <Skeleton className="h-[220px] w-[440px]" />

          <div className="flex flex-1 justify-between">
            <Skeleton className="h-[140px] w-[220px]" />
            <Skeleton className="h-[140px] w-[220px]" />
            <Skeleton className="h-[140px] w-[220px]" />
          </div>
        </div>
      </div>
    </section>
  )
}

const DashboardOverview = ({ fund }) => {
  const [currency, setCurrency] = useState<CurrencyIso>(fund.options.currency)
  const {
    isLoading,
    error,
    data: dataAnalytics,
  } = useFinancialAnalyticsQuery(fund.id, currency)
  const financials = dataAnalytics?.results
  const fundTotalAmount =
    currency === 'USD'
      ? fund.options.amountUsd!
      : currency === 'EUR'
      ? fund.options.amountEur!
      : fund.options.amount!
  const totalAllocated = financials?.allocatedFunds
    ? utils.sumValues(financials.allocatedFunds)
    : 0
  const totalAwarded = financials?.awardedFunds
    ? utils.sumValues(financials.awardedFunds)
    : 0
  const totalPaid = financials?.paidFunds
    ? utils.sumValues(financials.paidFunds)
    : 0

  if (isLoading) {
    return <LoadingSkeleton />
  }

  if (error) {
    return (
      <div className="max-w-changex relative mx-auto flex items-center justify-center gap-2">
        <Text variant="error">
          There was an error trying to fetch the Fund Analytics Data
        </Text>
      </div>
    )
  }

  return (
    <section className="bg-white py-6">
      <div className="max-w-changex relative mx-auto">
        <div className="flex justify-between">
          <h1 className="text-3xl text-teal-900">Overview</h1>
          <CurrencyFilter
            fundCurrency={fund.options.currency}
            selectedCurrency={currency}
            setSelectedCurrency={setCurrency}
          />
        </div>
        {fund.internalIdentifier && (
          <h2 className="text-sm text-gray-600">{fund.internalIdentifier}</h2>
        )}

        <div className="mt-6 flex items-center gap-5">
          <FundSummary
            label="Total funding"
            tooltip="The total amount of funding available for applications. This is what is displayed on the fund landing page."
            currency={currency}
            amount={fundTotalAmount}
            isLive={fund.isLive}
            startDate={new Date(fund.options.startDate)}
            endDate={new Date(fund.options.endDate)}
            fundType={fund.options.type}
          />

          <div className="flex flex-1 justify-between">
            <FinancialStat
              currency={currency}
              label="Allocated"
              tooltip="Funding that has been allocated, approved and paid out to applicants and social innovators."
              amount={totalAllocated}
              remainder={fundTotalAmount - totalAllocated}
            />

            <FinancialStat
              currency={currency}
              label="Awarded"
              tooltip="Replication funding approved, open grant funding allocated and approved, and all funding paid out to applicants and social innovators."
              amount={totalAwarded}
              remainder={fundTotalAmount - totalAwarded}
            />

            <FinancialStat
              currency={currency}
              label="Paid out"
              tooltip="Funding that has been paid to applicants and social innovators."
              amount={totalPaid}
              remainder={fundTotalAmount - totalPaid}
            />
          </div>
        </div>

        <ExpandCollapse className="mt-4 flex flex-col gap-4">
          {fund.options.type === FundType.Hybrid ? (
            <HybridStats
              currency={currency}
              fundTotalAmount={fundTotalAmount}
              requestedFunds={financials!.totalRequested}
              awardedFunds={financials!.awardedFunds}
              waitingFunds={financials!.waitingFunds}
            />
          ) : (
            <NonHybridStats
              currency={currency}
              fundType={fund.options.type}
              fundTotalAmount={fundTotalAmount}
              requestedFunds={financials!.totalRequested}
              waitingFunds={financials!.waitingFunds}
            />
          )}
        </ExpandCollapse>
      </div>
    </section>
  )
}

export default DashboardOverview
