import { TFundApplicationStatus, FundType } from './types'

export const APPLICATIONS_PAGE_SIZE = 10
export const DRAFT_APPLICATIONS_PAGE_SIZE = 20

const constants = {
  defaultSteps: [
    'video',
    'call',
    'guide',
    'meeting',
    'invite',
    'event',
    'post',
    'impact',
    'survey',
  ],
  steps: [
    { id: '', label: 'Current challenge step' },
    { id: 'video', label: '1 Video' },
    { id: 'call', label: '2 Call' },
    { id: 'guide', label: '3 Guide' },
    { id: 'meeting', label: '4 Meeting' },
    { id: 'invite', label: '5 Invite' },
    { id: 'event', label: '6 Event' },
    { id: 'post', label: '7 Post' },
    { id: 'impact', label: '8 Impact' },
    { id: 'survey', label: '9 Survey' },
  ],
  funds: [{ id: '', label: 'Fund' }],
  solutions: [{ id: '', label: 'Idea' }],
  defaultFilters: {
    filter: {
      'stage[neq]': 'draft',
    },
    page: {
      size: APPLICATIONS_PAGE_SIZE,
    },
    sort: 'date_created',
    stats: {
      total: 'count',
    },
    include: [
      'onboarding_steps',
      'fund',
      'solution',
      'location',
      'owner',
      'completed_onboarding_steps',
    ],
  },
  contents: [
    { id: '', label: 'Content' },
    { id: 'application', label: 'Applications' },
    { id: 'event', label: 'Events' },
    { id: 'location_post,solution_post', label: 'Posts' },
  ],
  states: [
    { id: '', label: 'Status' },
    { id: 'allocated', label: 'Allocated' },
    { id: 'succeeded', label: 'Succeeded' },
    { id: 'approved', label: 'Approved' },
    { id: 'paid_seed', label: 'Paid seed' },
    { id: 'impact', label: 'Impact' },
    { id: 'paid_impact', label: 'Paid impact' },
    { id: 'failed_impact', label: 'Failed impact', divider: true },
    { id: 'refunded', label: 'Refunded' },
    { id: 'unfunded', label: 'Unfunded' },
  ],
  projectTypes: [
    { id: '', label: 'Project type' },
    { id: 'any', label: 'Any' },
    { id: 'replication', label: 'Replication' },
    { id: 'open_grant', label: 'Open grant' },
  ],
  dateRanges: [
    { id: '', label: 'Date range' },
    { id: 'since_launch', label: 'Since launch' },
    { id: 'today', label: 'Today' },
    { id: 'past_seven_days', label: 'Past 7 days' },
    { id: 'past_thirty_days', label: 'Past 30 days' },
    { id: 'past_ninety_days', label: 'Past 90 days' },
    { id: 'past_twelve_months', label: 'Past 12 months' },
  ],
  stateCategories: [
    {
      category: 'Funded',
      options: [
        { id: 'paid_seed', label: 'Paid seed' },
        { id: 'impact', label: 'Impact' },
        { id: 'paid_impact', label: 'Paid impact' },
      ],
    },
    {
      category: 'Other',
      options: [
        { id: 'pre_allocated', label: 'Pre allocated' },
        { id: 'allocated', label: 'Allocated' },
        { id: 'succeeded', label: 'Succeeded' },
        { id: 'approved', label: 'Approved' },
        { id: 'failed', label: 'Failed' },
        { id: 'failed_impact', label: 'Failed impact' },
        { id: 'refunded', label: 'Refunded' },
        { id: 'rejected', label: 'Rejected' },
      ],
    },
  ],
}

export { constants }

export const APPLICATIONS_SORT_CRITERIA = [
  { id: 'date_applied', label: 'Date applied' },
  { id: 'recently_updated', label: 'Recently updated' },
  {
    id: 'challenge_days_remaining',
    label: 'Challenge days remaining',
  },
] as const

export const DRAFT_APPLICATIONS_SORT_CRITERIA = [
  { id: 'date_created', label: 'Date created' },
  { id: 'recently_updated', label: 'Recently updated' },
] as const

export const APPLICATIONS_STATUS: {
  [key in TFundApplicationStatus]: { label: string }
} = {
  pre_allocated: {
    label: 'Preallocated',
  },
  allocated: {
    label: 'Allocated',
  },
  succeeded: {
    label: 'Succeeded',
  },
  approved: {
    label: 'Approved',
  },
  paid_seed: {
    label: 'Paid seed',
  },
  impact: {
    label: 'Impact',
  },
  paid_impact: {
    label: 'Paid impact',
  },
  failed: {
    label: 'Failed',
  },
  failed_impact: {
    label: 'Failed impact',
  },
  rejected: {
    label: 'Rejected',
  },
  refunded: {
    label: 'Refunded',
  },
  unfunded: {
    label: 'Not funded',
  },
}

type TabInfo = {
  label: string
  key: string
  description: string
  statusId: TFundApplicationStatus
  type?: 'project_applications' | 'solution_applications'
}

/**
 * Tab count to show on main view without clicking into the overflow menu
 */
export const APPLICATIONS_TABS_TO_SHOW_COUNT = 8

export const APPLICATIONS_TAB_LIST = (fundType: FundType | undefined) => {
  const items: TabInfo[] = []
  if (fundType === FundType.Hybrid || fundType === FundType.Open) {
    items.push({
      label: 'Open grants inbox',
      key: 'openGrantInbox',
      description:
        "These are recently submitted open-grant applications. You'll need to review these after the fund close date, to decide which applications to allocate funding to.",
      statusId: TFundApplicationStatus.pre_allocated,
      type: 'project_applications',
    })
  }
  if (fundType === FundType.Hybrid || fundType === FundType.Replication) {
    items.push({
      label: 'Replications inbox',
      key: 'replicationInbox',
      description:
        'These are recently submitted applications for proven ideas. These will be automatically allocated funding if the starter is active on the ChangeX platform (eg. opening emails, logging in to their account, working on the 30 Day Challenge).',
      statusId: TFundApplicationStatus.pre_allocated,
      type: 'solution_applications',
    })
  }
  return items.concat([
    {
      label: 'Allocated',
      key: 'allocated',
      description:
        'Funding is reserved for these applications. Replication applications will work towards completing the 30 Day Challenge to unlock their first payment, the seed funding.',
      statusId: TFundApplicationStatus.allocated,
    },
    {
      label: 'Succeeded',
      key: 'succeeded',
      description:
        "These applicants have completed the 30 Day Challenge. You can review their challenge progress, then approve them once you're satisfied that they've completed the step(s).",
      statusId: TFundApplicationStatus.succeeded,
    },
    {
      label: 'Approved',
      key: 'approved',
      description:
        'These applications have been reviewed after completing the 30 Day Challenge and are ready to be paid their seed funding.',
      statusId: TFundApplicationStatus.approved,
    },
    {
      label: 'Paid seed',
      key: 'paidSeed',
      description:
        "These applicants have been paid their seed funding to support their project. They'll report back on their impact in a few months.",
      statusId: TFundApplicationStatus.paid_seed,
    },
    {
      label: 'Impact',
      key: 'impact',
      description:
        'These applicants have reported on their impact. You can review their submission before paying the final portion of their funding.',
      statusId: TFundApplicationStatus.impact,
    },
    {
      label: 'Paid impact',
      key: 'paidImpact',
      description:
        'These applicants have successfully reported impact and been approved for all of their funding.',
      statusId: TFundApplicationStatus.paid_impact,
    },
    {
      label: 'Failed',
      key: 'failed',
      description:
        'Applications that have not completed the 30 day challenge in the required 30 days.',
      statusId: TFundApplicationStatus.failed,
    },
    {
      label: 'Failed impact',
      key: 'failedImpact',
      description:
        'Applications that received their seed funding but have not received their impact funding due to not fulfilling criteria.',
      statusId: TFundApplicationStatus.failed_impact,
    },
    {
      label: 'Rejected',
      key: 'rejected',
      description: 'Applications rejected and no longer allocated funding.',
      statusId: TFundApplicationStatus.rejected,
    },
    {
      label: 'Refunded',
      key: 'refunded',
      description:
        'Applications by those unable to complete their projects and who have refunded ChangeX with the funds that they received.',
      statusId: TFundApplicationStatus.refunded,
    },
  ])
}
