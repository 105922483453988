import { TDraftOpenGrantApplication } from '../../../types'
import DraftApplicationOwner from './draft-application-owner'
import DraftApplicationInfo from './draft-application-info'
import { formatDateShort } from '@changex/design-system'

function DraftApplication({
  application,
}: {
  application: TDraftOpenGrantApplication
}) {
  return (
    <div className="mb-4 rounded-md border border-gray-200 shadow-sm">
      <div className="px-6 py-7 hover:bg-gray-50">
        <div className="flex gap-10">
          <DraftApplicationOwner data={application} />
          <DraftApplicationInfo data={application} />
          <div className="flex w-40 gap-1">
            <span className="text-sm text-gray-500">Created at:</span>
            <span className="text-sm text-gray-900">
              {formatDateShort(new Date(application.createdAt))}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DraftApplication
